import ReactglyphsGenerator from '../components/Reactglyphs/Reactglyphs';
import { Helmet } from 'react-helmet';
const Reactglyphs = () => {


  return (
    <>
     <div>
        <Helmet>
          <title>Reactglyphs</title>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <div className="fixed h-screen w-screen bg-neutral-1">
        <div className='fixed w-full h-full flex justify-center items-center'>
          <div className='fixed left-0 top-0 md:pt-20 pt-16 px-4 md:px-8 '>
            <div className='text-neutral-10 text-base font-medium'>Reactglyphs</div>
            <div className='text-neutral-9 text-xs'>Inspired by <span className='underline z-[50]'><a href='https://www.larvalabs.com/autoglyphs' target="_blank" rel="noreferrer">Autoglyphs</a></span></div>
          </div>
          <div className='w-fit'><ReactglyphsGenerator /></div>
        </div>
      </div>
    </>
  );
};

export default Reactglyphs;