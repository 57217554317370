// import headshot from '../assets/headhshot.png'
import About from '../components/home/About';
import EducationWork from '../components/home/EducationWork';

const AboutPage = () => {

  return (
    <>
      <About />
      <EducationWork />
    </>
  );
};

export default AboutPage;