import headshot from "../../assets/headhshot.png";

const About = () => {
  return (
    <>
      <div id="about"></div>
      <div className="bg-[#fff] flex items-center justify-center text-neutral-9 px-8 py-12 md:py-32">
        <div className="flex flex-col md:flex-row gap-14 max-w-5xl w-full">
          <img
            className="h-[350px] w-[350px]"
            src={headshot}
            alt="Tech Consulting Dashboard"
          />
          <div className="max-w-5xl w-full flex gap-9 flex-col">
            <div className="text-neutral-9 text-5xl font-medium">About Ben</div>
            <div className="text-base md:text-lg text-neutral-8">
              I'm a designer who is passionate about making technology easy and
              enjoyable to use.
            </div>
            <div className="text-base md:text-lg text-neutral-8">
              Currently, I contribute my design expertise to Ford Motor
              Company's Manufacturing IT - Digital Engineering department as a
              Product Designer, and to PIXO VR's product development team as a
              UX Design Consultant.
            </div>
            <div className="text-base md:text-lg text-neutral-8">
              When I'm not working, (and when the temperamental Ann Arbor
              weather allows for it), you can find me on a Pickleball court with
              friends, or working out in the garage home gym I built during the
              start of the pandemic.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
