import AutoglyphsGenerator from "./Autoglyphs";

const Landing = () => {
  console.log("Howdy");

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  return (
    <>
      <AutoglyphsGenerator />
      <div
        id="landing"
        className="flex flex-col items-center justify-center px-8"
      >
        <div className="h-screen flex items-center relative ">
          <div className="flex gap-12 flex-col items-center bg-[#FFFFFF80] backdrop-blur-sm saturate-200 border-2 border-white p-4 rounded-xl">
            <div className="text-6xl md:text-8xl text-center font-medium  text-[#000]">
              Ben Greenberg
            </div>
            <div className="text-3xl md:text-[40px] text-neutral-7">
              UX Designer
            </div>
          </div>
          <button
            aria-label="Scroll to projects"
            onClick={() => scrollToElement("projects")}
            className="absolute bottom-0 left-0 right-0 w-full mb-28 md:mb-10 flex justify-center scroll-down-cta"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              className="w-14 h-14 stroke-neutral-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Landing;
