import React, { useState, useEffect } from 'react';

const ReactglyphsGenerator = () => {
  const [generatedString, setGeneratedString] = useState('');
  const [isColorChangeOpen, setIsColorChangeOpen] = useState(false);
  const [textColor, setTextColor] = useState(() => {
    const storedTextColor = localStorage.getItem('textColor');
    return storedTextColor ? storedTextColor : 'text-black'; // Default color is black if not stored
  });
  const [bgColor, setBgColor] = useState(() => {
    const storedBgColor = localStorage.getItem('bgColor');
    return storedBgColor ? storedBgColor : 'bg-white'; // Default color is black if not stored
  });

  const generateNewText = () => {
    const ONE = 2 ** 32;
    const USIZE = 64;
    const SIZE = USIZE;
    const HALF_SIZE = SIZE / 2;
    const symbols = ['.', 'X', '/', '\\', '+', '-', '|', '#', 'O'];

    const absValue = (n) => (n >= 0 ? n : -n);

    const drawUsingTime = () => {
      const a = Math.floor(new Date().getTime() / 3);
      let output = '';

      for (let i = 0; i < SIZE; i++) {
        let y = 2 * (i - HALF_SIZE) + 1;
        if (a % 3 === 1) y = -y;
        else if (a % 3 === 2) y = absValue(y);
        y = y * a;

        for (let j = 0; j < SIZE; j++) {
          let x = 2 * (j - HALF_SIZE) + 1;
          if (a % 2 === 1) x = absValue(x);
          x = x * a;

          const v = absValue(Math.floor((x * y) / ONE) % ((a % 11) + 5));

          if (v < symbols.length) {
            output += symbols[v];
          } else {
            output += '.';
          }
        }
        output += '\n';
      }

      return output;
    };

    const newGeneratedString = drawUsingTime();
    setGeneratedString(newGeneratedString);
    localStorage.setItem('generatedString', newGeneratedString); // Store in localStorage
  };

  useEffect(() => {
    const existingGeneratedString = localStorage.getItem('generatedString');
    if (existingGeneratedString) {
      setGeneratedString(existingGeneratedString);
    } else {
      generateNewText();
    }
  }, []);

  const handleSetTextBlack = () => {
    setTextColor('text-black');
    localStorage.setItem('textColor', 'text-black');
  };

  const handleSetTextWhite = () => {
    setTextColor('text-white');
    localStorage.setItem('textColor', 'text-white');
  };
  const handleSetTextPink = () => {
    setTextColor('text-[#FF1EB7]');
    localStorage.setItem('textColor', 'text-[#FF1EB7]');
  };
  const handleSetTextBlue = () => {
    setTextColor('text-[#01CDFE]');
    localStorage.setItem('textColor', 'text-[#01CDFE]');
  };
  const handleSetTextGreen = () => {
    setTextColor('text-[#05FFA1]');
    localStorage.setItem('textColor', 'text-[#05FFA1]');
  };
  const handleSetTextPurple = () => {
    setTextColor('text-[#A635FF]');
    localStorage.setItem('textColor', 'text-[#A635FF]');
  };
  const handleSetTextYellow = () => {
    setTextColor('text-[#FFFB87]');
    localStorage.setItem('textColor', 'text-[#FFFB87]');
  };

  const handleSetBgBlack = () => {
    setBgColor('bg-black');
    localStorage.setItem('bgColor', 'bg-black');
  };
  const handleSetBgWhite = () => {
    setBgColor('bg-white');
    localStorage.setItem('bgColor', 'bg-white');
  };
  const handleSetBgPink = () => {
    setBgColor('bg-[#FF1EB7]');
    localStorage.setItem('bgColor', 'bg-[#FF1EB7]');
  };
  const handleSetBgBlue = () => {
    setBgColor('bg-[#01CDFE]');
    localStorage.setItem('bgColor', 'bg-[#01CDFE]');
  };
  const handleSetBgGreen = () => {
    setBgColor('bg-[#05FFA1]');
    localStorage.setItem('bgColor', 'bg-[#05FFA1]');
  };
  const handleSetBgPurple = () => {
    setBgColor('bg-[#A635FF]');
    localStorage.setItem('bgColor', 'bg-[#A635FF]');
  };
  const handleSetBgYellow = () => {
    setBgColor('bg-[#FFFB87]');
    localStorage.setItem('bgColor', 'bg-[#FFFB87]');
  };

  return (
    <>
      <div className='flex md:gap-6 gap-2 md:ml-[72px] flex-col-reverse items-end md:items-start md:flex-row ml-0'>
        <div className={`sm:text-[6px] text-[4px] p-8 sm:p-10 md:p-16 ${textColor} ${bgColor}`} id='reactglyph'>
          {generatedString}
        </div>
        <div className='relative flex md:flex-col gap-2'>
          <button aria-label='New Reactglyph' className='bg-white w-fit p-2 md:p-3 rounded-full hover:scale-110 active:scale-100 transition ease-in-out delay-75 stroke-black border border-neutral-3 hover:border-black' onClick={() => {generateNewText(); handleSetTextBlack(); handleSetBgWhite(); setIsColorChangeOpen(false)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
            </svg>
          </button>
          <button aria-label='Open Color Change Menu' className='bg-white w-fit p-2 md:p-3 rounded-full hover:scale-110 active:scale-100 transition ease-in-out delay-75 stroke-black border border-neutral-3 hover:border-black' onClick={() => setIsColorChangeOpen(!isColorChangeOpen)}>
            {isColorChangeOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="13.5" cy="6.5" r=".5" /><circle cx="17.5" cy="10.5" r=".5"/><circle cx="8.5" cy="7.5" r=".5"/><circle cx="6.5" cy="12.5" r=".5"/><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10c.926 0 1.648-.746 1.648-1.688 0-.437-.18-.835-.437-1.125-.29-.289-.438-.652-.438-1.125a1.64 1.64 0 0 1 1.668-1.668h1.996c3.051 0 5.555-2.503 5.555-5.554C21.965 6.012 17.461 2 12 2z"/>
              </svg>
            )}
          </button>
          {isColorChangeOpen && (
            <div className="absolute right-0 mt-12 md:mt-28">
              <div className='flex gap-3 nav-fade-animation bg-[#FFFFFFCC] backdrop-blur-3xl backdrop-saturate-200 flex-col border border-neutral-3 shadow-md w-fit p-4 my-1 rounded-lg'>
                <div className='flex flex-col gap-2'>
                  <div className='text-sm font-medium text-neutral-9'>Symbol</div>
                  <div className='flex gap-1'>
                    <button aria-label='Set Black' onClick={handleSetTextBlack} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-black' ? 'stroke-white' : 'stroke-black'}`}>
                      <div className='p-0.5 rounded-full bg-black border-black'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set White' onClick={handleSetTextWhite} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-white' ? 'stroke-black' : 'stroke-white'}`}>
                      <div className='p-0.5 rounded-full bg-white border'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Pink' onClick={handleSetTextPink} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-[#FF1EB7]' ? 'stroke-black' : 'stroke-[#FF1EB7]'}`}>
                      <div className='p-0.5 rounded-full bg-[#FF1EB7] border border-[#FF1EB7]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Blue' onClick={handleSetTextBlue} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-[#01CDFE]' ? 'stroke-black' : 'stroke-[#01CDFE]'}`}>
                      <div className='p-0.5 rounded-full bg-[#01CDFE] border border-[#01CDFE]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Green' onClick={handleSetTextGreen} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-[#05FFA1]' ? 'stroke-black' : 'stroke-[#05FFA1]'}`}>
                      <div className='p-0.5 rounded-full bg-[#05FFA1] border border-[#05FFA1]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Purple' onClick={handleSetTextPurple} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-[#A635FF]' ? 'stroke-black' : 'stroke-[#A635FF]'}`}>
                      <div className='p-0.5 rounded-full bg-[#A635FF] border border-[#A635FF]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Yellow' onClick={handleSetTextYellow} className={`h-fit w-fit rounded-full hover:scale-105 ${textColor === 'text-[#FFFB87]' ? 'stroke-black' : 'stroke-[#FFFB87]'}`}>
                      <div className='p-0.5 rounded-full bg-[#FFFB87] border'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                  </div>
                </div>
                <div className='flex flex-col gap-2'>
                  <div className='text-sm font-medium text-neutral-9'>Background</div>
                  <div className='flex gap-1'>
                    <button aria-label='Set Black' onClick={handleSetBgBlack} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-black' ? 'stroke-white' : 'stroke-black'}`}>
                      <div className='p-0.5 rounded-full bg-black border'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set White' onClick={handleSetBgWhite} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-white' ? 'stroke-black' : 'stroke-white'}`}>
                      <div className='p-0.5 rounded-full bg-white border'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Pink' onClick={handleSetBgPink} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-[#FF1EB7]' ? 'stroke-black' : 'stroke-[#FF1EB7]'}`}>
                      <div className='p-0.5 rounded-full bg-[#FF1EB7] border border-[#FF1EB7]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Blue' onClick={handleSetBgBlue} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-[#01CDFE]' ? 'stroke-black' : 'stroke-[#01CDFE]'}`}>
                      <div className='p-0.5 rounded-full bg-[#01CDFE] border border-[#01CDFE]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Green' onClick={handleSetBgGreen} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-[#05FFA1]' ? 'stroke-black' : 'stroke-[#05FFA1]'}`}>
                      <div className='p-0.5 rounded-full bg-[#05FFA1] border border-[#05FFA1]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Purple' onClick={handleSetBgPurple} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-[#A635FF]' ? 'stroke-black' : 'stroke-[#A635FF]'}`}>
                      <div className='p-0.5 rounded-full bg-[#A635FF] border border-[#A635FF]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                    <button aria-label='Set Yellow' onClick={handleSetBgYellow} className={`h-fit w-fit rounded-full hover:scale-105 ${bgColor === 'bg-[#FFFB87]' ? 'stroke-black' : 'stroke-[#FFFB87]'}`}>
                      <div className='p-0.5 rounded-full bg-[#FFFB87] border'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReactglyphsGenerator;
