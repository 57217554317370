import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Outlet, useLocation } from "react-router-dom";
import resume from "../assets/BGreenberg-Resume.pdf";

const Layout = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [isGraphicuiOpen, setIsGraphicuiOpen] = useState(false);
  const location = useLocation();

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };
  const handleNavClose = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    const hash = location.hash;

    if (hash && hash.startsWith("#")) {
      const id = hash.substring(1);

      if (location.state && location.state.fromDifferentPage) {
        // Coming from a different page to the homepage
        setTimeout(() => {
          scrollToElement(id);
        }, 5000); // Adding a slight delay to ensure correct scrolling
      }
    }
  }, [location]);

  return (
    <>
      <nav
        className={`"items-end fixed right-0 z-50 text-sm text-neutral-8 rounded-full ${
          location.pathname === "/" ? "w-fit" : "w-full flex justify-between"
        }`}
      >
        <div
          className={`flex justify-between m-2 md:m-3 ${
            location.pathname === "/" ? "hidden" : "visible"
          }`}
        >
          <Link
            to="/"
            className="bg-[#fff] border border-neutral-3 bg-opacity-90 hover:border-black hover:scale-110 active:scale-100 transition ease-in-out delay-75 p-2 md:p-3 rounded-full"
            aria-label="Toggle Overlay"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.6"
              stroke="#000000"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </Link>
        </div>
        <div className="flex justify-between m-2 md:m-3">
          <button
            className="bg-white border border-neutral-3 bg-opacity-90 hover:border-black hover:scale-110 active:scale-100 transition ease-in-out delay-75 p-2 md:p-3 rounded-full"
            aria-label="Toggle Overlay"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="#000000"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          {isNavOpen && (
            <div className="fixed top-0 right-0 w-fit h-fit">
              <div className="flex">
                <div className="w-full flex justify-end p-2 md:p-3">
                  <button
                    aria-label="Close Overlay"
                    className="p-2 md:p-3 bg-[#fff] border border-neutral-3 hover:scale-110 hover:border-black active:scale-100 transition ease-in-out delay-75 rounded-full"
                    onClick={() => setIsNavOpen(!isNavOpen)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="#000000"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="flex nav-fade-animation bg-[#FFFFFFCC] backdrop-blur-3xl backdrop-saturate-200 flex-col border border-neutral-3 shadow-sm w-80 mx-3 px-2 my-1 rounded-lg">
                <a
                  href="/"
                  onClick={handleNavClose}
                  className={`rounded-md text-base mt-2 px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                    location.pathname === "/"
                      ? "text-[#000] font-semibold"
                      : "text-neutral-7"
                  }`}
                >
                  Home
                </a>
                <Link
                  to="about"
                  onClick={handleNavClose}
                  className={`rounded-md text-base px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                    location.pathname === "/about"
                      ? "text-[#000] font-semibold"
                      : "text-neutral-7"
                  }`}
                >
                  About
                </Link>
                <div className="flex items-center gap-2">
                  <a
                    href="/#projects"
                    onClick={handleNavClose}
                    className={`rounded-md text-base px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                      location.pathname === "/projects"
                        ? "text-[#000] font-semibold"
                        : "text-neutral-7"
                    }`}
                  >
                    Projects
                  </a>
                  <button
                    className={`mr-2 h-fit p-3 rounded-full hover:scale-110 active:scale-100 transition ease-in-out delay-75 hover:bg-neutral-1 stroke-neutral-6 hover:stroke-black ${
                      location.pathname === "/projects"
                        ? "text-[#000] font-semibold"
                        : "text-neutral-7"
                    }`}
                    onClick={() => setIsProjectsOpen(!isProjectsOpen)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      className={`w-4 h-4 ${isProjectsOpen && "rotate-180"}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                </div>
                {isProjectsOpen && (
                  <>
                    <Link
                      to="tcd"
                      onClick={handleNavClose}
                      className={`rounded-md text-sm pl-6 px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                        location.pathname === "/tcd"
                          ? "text-[#000] font-semibold"
                          : "text-neutral-7"
                      }`}
                    >
                      Tech Consulting Dashboard
                    </Link>
                    <Link
                      to="parkit"
                      onClick={handleNavClose}
                      className={`rounded-md text-sm pl-6 px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                        location.pathname === "/parkit"
                          ? "text-[#000] font-semibold"
                          : "text-neutral-7"
                      }`}
                    >
                      Parkit
                    </Link>
                    <Link
                      to="cromaine"
                      onClick={handleNavClose}
                      className={`rounded-md text-sm pl-6 px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                        location.pathname === "/cromaine"
                          ? "text-[#000] font-semibold"
                          : "text-neutral-7"
                      }`}
                    >
                      Cromaine Library Website Redesign
                    </Link>
                  </>
                )}
                <div className="flex items-center gap-2">
                  <a
                    href="/#graphic-ui"
                    onClick={handleNavClose}
                    className={`rounded-md text-base px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                      location.pathname === "/projects"
                        ? "text-[#000] font-semibold"
                        : "text-neutral-7"
                    }`}
                  >
                    Graphic/UI
                  </a>
                  <button
                    className={`mr-2 h-fit p-3 rounded-full hover:scale-110 active:scale-100 transition ease-in-out delay-75 hover:bg-neutral-1 stroke-neutral-6 hover:stroke-black ${
                      location.pathname === "/projects"
                        ? "text-[#000] font-semibold"
                        : "text-neutral-7"
                    }`}
                    onClick={() => setIsGraphicuiOpen(!isGraphicuiOpen)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      className={`w-4 h-4 ${isGraphicuiOpen && "rotate-180"}`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                </div>
                {isGraphicuiOpen && (
                  <>
                    <Link
                      to="alphabet-poster"
                      onClick={handleNavClose}
                      className={`rounded-md text-sm pl-6 px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                        location.pathname === "/alphabet-poster"
                          ? "text-[#000] font-semibold"
                          : "text-neutral-7"
                      }`}
                    >
                      Alphabet Inventions Poster
                    </Link>
                    <Link
                      to="internship-infographic"
                      onClick={handleNavClose}
                      className={`rounded-md text-sm pl-6 px-4 py-3 hover:text-[#000] hover:bg-neutral-1 transition-all duration-200 ease-in w-full ${
                        location.pathname === "/internship-infographic"
                          ? "text-[#000] font-semibold"
                          : "text-neutral-7"
                      }`}
                    >
                      City of Ann Arbor Internship Infographic
                    </Link>
                  </>
                )}
                <hr className="border border-neutral-1 my-2" />
                <div className="flex w-full mb-2">
                  <a
                    href="https://www.linkedin.com/in/bengrnb/"
                    className="rounded-md flex-1 flex items-center hover:bg-neutral-1 text-neutral-6 hover:text-neutral-11 stroke-neutral-6 hover:stroke-black text-base pl-4 pr-1 py-3 font-semibold transition-all duration-200 ease-in w-fit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>Linkedin</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="transition-all duration-200 ease-in"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M13 5H19V11" />
                      <path d="M19 5L5 19" />
                    </svg>
                  </a>
                  <a
                    href={resume}
                    className="rounded-md flex-1 flex items-center hover:bg-neutral-1 text-neutral-6 hover:text-neutral-11 stroke-neutral-6 hover:stroke-black text-base pl-4 pr-1 py-3 font-semibold transition-all duration-200 ease-in w-fit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>Resume</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="transition-all duration-200 ease-in"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M13 5H19V11" />
                      <path d="M19 5L5 19" />
                    </svg>
                  </a>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-4 h-4 stroke-neutral-8">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Layout;
