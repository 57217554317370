import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import mysterious_armadillo from "../assets/mysterious_armadillo.jpg"

const NoMatch = () => {

  return (
    <>
        <div>
          <Helmet>
            <title>NoMatch</title>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29`} />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-Q6F2WV9G29');
              `}
            </script>
          </Helmet>
        </div>
        <div className="h-screen flex justify-center items-center flex-col gap-12">
          <div className="text-4xl text-neutral-8 text-center">This page doesn't exist</div>
          <img className="h-48 rounded-xl" src={mysterious_armadillo} alt="Mysterious Armadillo"/>
          <Link to="/"  className='text-neutral-9 font-normal text-xl md:text-2xl w-96 md:w-[512px] bg-neutral-1 p-4 md:p-6 text-center rounded-full border border-[#fff] hover:bg-[#fff] hover:scale-110 transition ease-in-out delay-75'>Take Me Home</Link>
        </div>
        <p>
        </p>
    </>
  );
  }
  export default NoMatch;