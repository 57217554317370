import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToAnchor from './ScrollToAnchor';
import '../styles/styles.css';
import '../styles/normalize.css';
import Layout from './Layout';
import Footer from './footer';
import ScrollToTop from './ScrollToTop';
import NoMatch from '../pages/NoMatch';
import Home from '../pages/Home';
import AboutPage from '../pages/AboutPage';
import Cromaine from '../pages/Cromaine';
import Tcd from '../pages/Tcd';
import Parkit from '../pages/Parkit';
import AlphabetPoster from '../pages/AlphabetPoster';
import InternshipInfographic from '../pages/InternshipInfographic';
import Harmony from '../pages/Harmony';
import Reactglyphs from '../pages/Reactglyphs';
// import Pxlsnft from '../pages/Pxlsnft';

const App = () => {

  return (
    <>
      <div>
        <Helmet>
          <title>Home</title>
          {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script> */}
        </Helmet>
      </div>
      <ScrollToAnchor />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<AboutPage />} />
          <Route path="cromaine" element={<Cromaine />} />
          <Route path="parkit" element={<Parkit />} />
          <Route path="tcd" element={<Tcd />} />
          <Route path="alphabet-poster" element={<AlphabetPoster />} />
          <Route path="internship-infographic" element={<InternshipInfographic />} />
          <Route path="harmony" element={<Harmony />} />
          <Route path="reactglyphs" element={<Reactglyphs />} />
          {/* <Route path="pxlsnft" element={<Pxlsnft />} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default App;
