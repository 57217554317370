import { Link } from "react-router-dom";
// import tcd from "../../graphicsicons/home/tcd.svg";
// import parkit from "../../graphicsicons/home/parkit.svg"
import cromaine from "../../graphicsicons/home/cromaine.svg";
import annarbor from "../../graphicsicons/home/annarbor.svg";
import alphabet from "../../graphicsicons/home/alphabet.svg";
// import harmony from "../../graphicsicons/home/harmony.svg"
// import pxlsnft from "../../graphicsicons/home/pxlsnft.png"

const SelectedWork = () => {
  return (
    <>
      <div
        id="projects"
        className="bg-[rgba(255,255,255,0.01)] backdrop-blur-sm flex flex-col items-center justify-center text-neutral-9 px-6 md:px-8 pt-12 md:pt-12 pb-0"
      >
        <div className="flex flex-col max-w-3xl">
          <div className="text-[#000] font-semibold text-4xl">
            Selected Work
          </div>
          <div className="flex flex-col gap-6 w-full pt-12">
            <div className="text-3xl text-neutral-9 w-full">Projects</div>
            <div className="flex gap-10 flex-wrap">
              <Link
                to="/tcd"
                className="shadow-sm w-full text-neutral-7 flex-col-reverse md:flex-row items-center flex gap-6 border p-6 bg-white border-neutral-3 hover:border-[rgba(0,0,0,0)] hover:shadow-[inset_0_0px_0px_2px_rgb(0,0,0,.3)] hover:scale-105 active:scale-100 rounded-3xl transition ease-in-out"
              >
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-1">
                    <div className="text-neutral-7 text-sm">2023</div>
                    <div className="text-[#000] font-semibold text-2xl">
                      Tech Consulting Dashboard
                    </div>
                    <div className="text-neutral-7 font-semibold text-base">
                      University of Michigan Information and Technology Services
                    </div>
                  </div>
                  <div className="text-neutral-8 text-sm">
                    Centralizing and improving the tools University of Michigan
                    IT Tech Consultants use in their daily work.
                  </div>
                </div>
                <div className="md:pl-10">
                  <svg
                    className="w-28 h-fit"
                    viewBox="0 0 100 107"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="60.4074"
                      height="11.8649"
                      rx="5.93243"
                      transform="matrix(0.861067 -0.508491 0.507525 0.861637 39.7053 57.2227)"
                      fill="#B3E5FF"
                    />
                    <rect
                      width="60.4074"
                      height="11.8649"
                      rx="5.93243"
                      transform="matrix(-0.861067 -0.508491 -0.507525 0.861637 60.6857 57.2227)"
                      fill="#B3E5FF"
                    />
                    <rect
                      width="60.4649"
                      height="11.8536"
                      rx="5.92679"
                      transform="matrix(-4.36554e-08 -1 -1 4.37674e-08 56.8577 102.866)"
                      fill="#B3E5FF"
                    />
                    <path
                      d="M14.2803 31.5529C10.4734 29.2659 10.4734 23.7479 14.2803 21.4608L46.971 1.82123C48.8366 0.700434 51.1684 0.700433 53.034 1.82123L85.7248 21.4608C89.5316 23.7478 89.5316 29.2659 85.7248 31.5529L53.034 51.1925C51.1684 52.3133 48.8366 52.3133 46.971 51.1925L14.2803 31.5529Z"
                      fill="#0D57AA"
                    />
                    <path
                      d="M52.937 62.3401C52.937 60.2145 54.0829 58.2541 55.9349 57.211L91.2246 37.3351C95.1487 35.125 100 37.9605 100 42.4642V76.0752C100 78.2008 98.8542 80.1612 97.0022 81.2043L61.7124 101.08C57.7884 103.29 52.937 100.455 52.937 95.9511V62.3401Z"
                      fill="#0477CC"
                    />
                    <path
                      d="M0 40.0837C0 35.4586 5.08876 32.6401 9.00943 35.0937L44.2991 57.178C46.0186 58.2541 47.063 60.1396 47.063 62.1681V95.9593C47.063 100.463 42.2116 103.299 38.2875 101.088L2.99784 81.2125C1.14585 80.1694 0 78.209 0 76.0834V40.0837Z"
                      fill="#009CF6"
                    />
                  </svg>
                </div>
              </Link>
              <Link
                to="/parkit"
                className="shadow-sm w-full text-neutral-7 flex-col md:flex-row items-center flex gap-6 border p-6 bg-white border-neutral-3 hover:border-[rgba(0,0,0,0)] hover:shadow-[inset_0_0px_0px_2px_rgb(0,0,0,.3)] hover:scale-105 active:scale-100 rounded-3xl transition ease-in-out"
              >
                <div className="md:pr-10">
                  <svg
                    className="w-28 h-fit"
                    viewBox="0 0 74 74"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M73.9089 0H0V73.9522H73.9089V0Z" fill="#FFDC13" />
                    <path
                      d="M54.0813 20.3077L44.2096 36.9161L54.0813 53.6105H46.0561L35.9712 36.9161L46.0561 20.3074L54.0813 20.3077Z"
                      fill="black"
                    />
                    <path
                      d="M38.419 20.3077L28.5472 36.9161L38.419 53.6105H30.3937L20.3088 36.9161L30.3937 20.3074L38.419 20.3077Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap1.5">
                    <div className="text-neutral-7 text-sm">2022</div>
                    <div className="text-[#000] font-semibold text-2xl">
                      Parkit
                    </div>
                    <div className="text-neutral-7 font-semibold text-base">
                      UX Research and Design Mastery Course Project
                    </div>
                  </div>
                  <div className="text-neutral-8 text-sm">
                    Creating a mutually beneficial exchange between residential
                    parking space owners and potential renters.
                  </div>
                </div>
              </Link>
              <Link
                to="/cromaine"
                className="shadow-sm w-full text-neutral-7 flex-col-reverse md:flex-row items-center flex gap-6 border p-6 bg-white border-neutral-3 hover:border-[rgba(0,0,0,0)] hover:shadow-[inset_0_0px_0px_2px_rgb(0,0,0,.3)] hover:scale-105 active:scale-100 rounded-3xl transition ease-in-out"
              >
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-1">
                    <div className="text-neutral-7 text-sm">2022</div>
                    <div className="text-[#000] font-semibold text-2xl">
                      Cromaine Library Website Redesign
                    </div>
                    <div className="text-neutral-7 font-semibold text-base">
                      Cromaine District Library
                    </div>
                  </div>
                  <div className="text-neutral-8 text-sm">
                    Creating desktop and mobile website prototypes of a
                    redesigned Library website to increase ease of use,
                    accessibility, and connectivity to the community.
                  </div>
                </div>
                <div className="md:pl-10">
                  <img
                    className="h-full"
                    src={cromaine}
                    alt="Cromaine Logo"
                  ></img>
                </div>
              </Link>
            </div>
          </div>
          <div id="graphic-ui" className="flex flex-col gap-6 w-full pt-32">
            <div className="text-3xl text-neutral-9 w-full">Graphic/UI</div>
            <div className="flex gap-10 flex-wrap">
              {/* <Link to="/harmony" className="h-60 w-full md:w-[360px] flex items-center justify-center bg-neutral-1 rounded-3xl border border-neutral-1 hover:bg-neutral-1 hover:scale-110 active:scale-100 transition ease-in-out delay-75">
                  <img className="h-[160px]" src={harmony} alt="Alphabet Poster" />
                </Link> */}
              <Link
                to="/alphabet-poster"
                className="shadow-sm w-full text-neutral-7 flex-col-reverse md:flex-row items-center flex gap-6 border p-6 bg-white border-neutral-3 hover:border-[rgba(0,0,0,0)] hover:shadow-[inset_0_0px_0px_2px_rgb(0,0,0,.3)] hover:scale-105 active:scale-100 rounded-3xl transition ease-in-out"
              >
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-1">
                    <div className="text-[#000] font-semibold text-2xl">
                      Alphabet Inventions Poster
                    </div>
                  </div>
                  <div className="text-neutral-8 text-sm">
                    An alphabet poster to showcase different inventions,
                    specifically older inventions with “patent art.”{" "}
                  </div>
                </div>
                <div className="md:pl-10">
                  <img
                    className="h-full"
                    src={alphabet}
                    alt="Alphabet Poster"
                  ></img>
                </div>
              </Link>
              <Link
                to="/internship-infographic"
                className="shadow-sm w-full text-neutral-7 flex-col md:flex-row items-center flex gap-6 border p-6 bg-white border-neutral-3 hover:border-[rgba(0,0,0,0)] hover:shadow-[inset_0_0px_0px_2px_rgb(0,0,0,.3)] hover:scale-105 active:scale-100 rounded-3xl transition ease-in-out"
              >
                <div className="md:pr-10">
                  <img
                    className="h-full"
                    src={annarbor}
                    alt="City of Ann Arbor"
                  ></img>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap1.5">
                    <div className="text-[#000] font-semibold text-2xl">
                      City of Ann Arbor Internship Infographic
                    </div>
                  </div>
                  <div className="text-neutral-8 text-sm">
                    An infographic of the different locations I visited in Ann
                    Arbor Michigan during my summer internship with the City of
                    Ann Arbor.
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedWork;
