import React from "react";
import { Helmet } from "react-helmet";
import ImageOverlay from "../base/ImageOverlay";
import redesigned_homepage from "../graphicsicons/cromaine/cromaine_redesign_home.png";
import cromaine_roadmap from "../graphicsicons/cromaine/cromaine_roadmap.png";
import cromaine_survey_device from "../graphicsicons/cromaine/cromaine_survey_device.png";
import cromaine_survey_frequency from "../graphicsicons/cromaine/cromaine_survey_frequency.png";
import cromaine_survey_most from "../graphicsicons/cromaine/cromaine_survey_most.png";
import cromaine_branding_before_after from "../graphicsicons/cromaine/cromaine_branding_before_after.png";
import cromaine_information_architecture_before_after from "../graphicsicons/cromaine/cromaine_information_architecture_before_after.png";
import cromaine_search_redesign from "../graphicsicons/cromaine/cromaine_search_redesign.png";
import cromaine_redesign_home_before_after from "../graphicsicons/cromaine/cromaine_redesign_home_before_after.png";
import cromaine_digital_resources from "../graphicsicons/cromaine/cromaine_digital_resources.png";
import cromaine_calendar_redesign from "../graphicsicons/cromaine/cromaine_calendar_redesign.png";

const Cromaine = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Cromaine</title>\
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <div className="bg-[#fff] flex items-center justify-center text-neutral-9 px-6 md:px-8 pt-[92px]">
        <div className="flex flex-col max-w-3xl w-full pt-14 gap-y-24">
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-4">
              <div className="text-neutral-10 text-4xl md:text-5xl font-medium">
                Cromaine District Library
              </div>
              <div className="text-neutral-9">January - April 2022</div>
            </div>
            <div className="flex md:flex-row flex-col gap-12">
              <div className="flex w-full flex-col gap-3 pt-6 border-t border-neutral-3">
                <div className="font-medium text-2xl text-[#000]">Client</div>
                <div className="font-normal text-base text-neutral-8">
                  Cromaine District Library
                </div>
              </div>
              <div className="flex w-full flex-col gap-3 pt-6 border-t border-neutral-3">
                <div className="font-medium text-2xl text-[#000]">Role</div>
                <div className="font-normal text-base text-neutral-8">
                  UX Researcher, UX Designer
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-20">
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap items-baseline gap-4 w-full justify-between">
                  <h3 className="text-neutral-9 text-2xl font-semibold">
                    Overview
                  </h3>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.figma.com/proto/6i7Feyp2fWFmSUFMPtnoKb/Cromaine-District-Library-Redesign?page-id=0%3A1&type=design&node-id=5-597&viewport=480%2C626%2C0.12&scaling=scale-down-width&starting-point-node-id=5%3A597&show-proto-sidebar=1"
                    className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
                  >
                    <div>View Figma Mockup</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="font-normal text-lg text-neutral-8">
                  Myself and three other UX teammates created desktop and mobile
                  website prototypes of a redesigned Cromaine District Library
                  website to increase ease of use, accessibility, and
                  connectivity to the community.
                </div>
              </div>
              <ImageOverlay
                src={redesigned_homepage}
                alt="Redesigned Homepage"
                normalWidth="w-full"
                normalHeight="h-auto"
                fullscreenWidth="w-full"
                fullscreenHeight="h-full"
                normalAdditionalClasses=""
                fullscreenAdditionalClasses=""
                showAlt={true}
              />
            </div>
          </div>
          <div className="textblock">
            <h2>Cultrual Center of the Community</h2>
            <p>
              In addition to being a place where people go to check out books,
              the Cromaine library also serves as a cultural hub for members of
              the Harland Michigan community to get information about the town,
              and access resources the library offers such as wifi hotspots and
              sporting equipment.
            </p>
            <p>
              When the Cromaine library connected with the University of
              Michigan's Design Clinic and contracted myself and three other UX
              teammates to assist them with redesigning their website, their
              site has not undergone any serious updates for 10 years!{" "}
              <span className="font-semibold">
                The site suffered from many issues such as: accessibility
                problems, technical problems, jargony language, and a confusing,
                overstimulating layout.
              </span>{" "}
              The library wanted to bring us in as a first step in a larger
              redesign project of the entire site to identify those problems and
              come up with solutions to them. Given the central role the public
              library played in connecting citizens to information and to each
              other via community events and activities, it was hoped that a
              major overhaul of the website would better serve all sectors of
              the community, from children/teens to young adults, to the
              elderly.
            </p>
          </div>
          <div className="textblock">
            <h2>The Problem</h2>
            <p>
              The Cromaine District Library website needed to redesigned in a
              way that it would be responsive, visually pleasing and usable for
              not just the next few years, but for the average lifecycle of a
              rural library, which could easily be five to ten years.
            </p>
          </div>
          <div className="textblock">
            <h2>Project Execution</h2>
            <ImageOverlay
              src={cromaine_roadmap}
              alt="Project Roadmap"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Google Analytics Research</h2>
            <p>
              Our team analyzed the Cromaine Library Google analytics to get a
              high-level understanding of who is using the Cromaine website and
              how it is being used. The analysis included which type of devices
              were being used to access the website, user age demographics and
              pageviews. Here are some key takeaways:
            </p>
            <p>
              &#x2022; While the majority (62.4%) of library patrons accessed
              the website using a desktop device, more than a third (35.7%)
              relied on their mobile device, with the remaining patrons using a
              tablet (1.9%), highlighting the need to redesign the website with
              attention to both desktop and mobile applications.
            </p>
            <p>
              &#x2022; The library website was found to be visited by a wide age
              range of patrons, from age 18 to 65+, in equal measure, with the
              exception of patrons ages 35-44 years, who were the most frequent
              users. The website's design must, then, be accessible and useable
              by this full developmental range.
            </p>
            <p>
              &#x2022; Over half of the visits to the website were to the
              homepage (53%), with much of the remaining visits focused on the
              summer reading program (15%) and the events/calendar (9%) pages.
              Because they are the most visited pages, we focused our redesign
              efforts on the Homepage and Events/Calendar pages. It became clear
              in our interview and survey user data, however, that the
              infrequency of visits to certain pages (e.g., those connecting
              patrons to desired digital resources and services) reflected
              design barriers to those pages, not lack of user interest.
              Survey/interview data (described below) highlighted the need to
              further focus redesign efforts on Digital Resources pages (e.g.,
              connecting patrons to external sites and resources they can access
              with their Cromaine library card).
            </p>
          </div>
          <div className="textblock">
            <h2>Quantitative Research</h2>
            <p>
              We conducted quantitative research by surveying 104 Croamine
              library patrons. Below are some of our questions and analysis of
              the responses:
            </p>
            <ImageOverlay
              src={cromaine_survey_device}
              alt="What type of device do you typically access the library website on?"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              showAlt={false}
            />
            <ImageOverlay
              src={cromaine_survey_frequency}
              alt="How frequently do you use the Cromaine District Library website?"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              showAlt={false}
            />
            <ImageOverlay
              src={cromaine_survey_most}
              alt="How frequently do you use the Cromaine District Library website?"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              showAlt={false}
            />
            <p>
              The quantitative findings reinforced the importance of attention
              to design for mobile devices, which had not been a priority for
              the existing website. The data also indicated the important role
              Cromaine and its website had in the community as an information
              hub: in addition to reserving and checking out books, patrons were
              interacting with the library's programs.
            </p>
          </div>
          <div className="textblock">
            <h2>Qualitative Research</h2>
            <p>
              We conducted qualitative research, through interviews, with
              library patrons and staff both in person and virtually over Zoom.
              We found that while patrons highly value the library and its
              services, they repeatedly expressed a range of frustrations with
              the website, including:
            </p>
            <p>
              &#x2022; It is confusing to execute even the simplest of tasks,
              such as finding a book through the library catalog.
            </p>
            <p>&#x2022; The site is visually overwhelming.</p>
            <p>&#x2022; The site lacks an intuitive, easy-to-learn design.</p>
            <p>In the library user's own words:</p>
            <div className="quoteblock">
              <p>
                "Just finding a book seems like it takes way more steps than it
                should. The home page is not user friendly. It's confusing where
                to do something simple, like finding a book. Need a bigger
                search box and buttons on the top and clearer, simple
                interface."
              </p>
              <p>"It's very difficult to use on my mobile devices"</p>
              <p>
                "It seemed hard to find things until I had done it many times."
              </p>
              <p>
                "It's flashy, distracting. I would prefer calmer colors, easier
                to navigate."
              </p>
              <p>"Searching can be difficult. I'm not that tech savvy."</p>
              <p>
                "It's too difficult to find what I need... it's not intuitive
                and there's a lot going on."
              </p>
              <p>
                "I dislike that it takes two clicks to get to the calendar. It
                would be lovely to have a 'View our Calendar' as a prominent
                icon on the main page."
              </p>
            </div>
          </div>
          <div className="textblock">
            <div className="flex flex-wrap items-center gap-4 w-full justify-between">
              <h2>Website Redesign</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.figma.com/proto/6i7Feyp2fWFmSUFMPtnoKb/Cromaine-District-Library-Redesign?page-id=0%3A1&type=design&node-id=5-597&viewport=480%2C626%2C0.12&scaling=scale-down-width&starting-point-node-id=5%3A597&show-proto-sidebar=1"
                className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
              >
                <div>View Figma Mockup</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                  />
                </svg>
              </a>
            </div>
            <p>
              Armed with the results of the quantitative and qualitative
              research, my teammates and I redesigned key pages and aspects of
              the Cromaine website that would be used to inform a larger
              redesign of the entire website by another team, after we handed
              off the project.
            </p>
            <p>From our research, we identified a few overarching goals:</p>
            <p>
              &#x2022; Frequently visited pages need to be easier to find and
              important information needs to be easy to find.
            </p>
            <p>
              &#x2022; Important information and the main navigation need to be
              redesigned so that they become easier to access.
            </p>
            <p>
              &#x2022; The site needed to be made accessible to all patrons and
              typography, color contrasts, and assets like the picture carousel
              on the homepage need to be altered to meet accessibility
              standards.
            </p>
          </div>
          <div className="textblock">
            <h2>Branding</h2>
            <p>
              Before jumping into prototyping, we evaluated the current branding
              guidelines for the library. We found that not all of the colors on
              the website were captured in the branding guidelines, so we made
              sure to document all of the colors as well as add a few others
              that were still on brand and did not create any color contrast
              accessibility problems like the light blue color and the neutral
              light color. Additionally, we used different typography that was
              more built out, looked more modern and clean, and supported more
              font variations.
            </p>
            <ImageOverlay
              src={cromaine_branding_before_after}
              alt="Cromaine branding before and after redesign"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Information Architecture</h2>
            <p>
              Instead of requiring the user to view all of the links from one
              visually overwhelming dropdown menu, in the redesign, we made the
              navigation bar smaller and more discoverable. We did so by
              removing redundant links, rewording navigation items to make them
              more user-friendly, refreshing the visual design of the header and
              footer and putting links where users were more likely to expect
              them.
            </p>
            <ImageOverlay
              src={cromaine_information_architecture_before_after}
              alt="Information architecture before and after"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Search</h2>
            <p>
              We made it easier to use the site's search feature, which was one
              of the most used but most difficult to find features on the
              website, by positioning the search to the right side of the nav
              bar and having it drop down below the nav bar when it was active.
            </p>
            <ImageOverlay
              src={cromaine_search_redesign}
              alt="Redesigned search component"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Homepage</h2>
            <p>
              Having analyzed what information users were actually using on the
              Homepage, we redesigned it so that only the most used resources
              and information were presented on the page, enhancing easy access
              to desired information. We simplified the layout of the Homepage,
              so that it could be easily updated and changed as the library's
              resources change over time. We demonstrated how the redesign would
              translate from Desktop to Mobile devices.
            </p>
            <ImageOverlay
              src={cromaine_redesign_home_before_after}
              alt="Homepage before and after"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Digital Resources</h2>
            <p>
              On the original website, the library's digital resources (i.e.,
              external websites that could be accessed through a Cromaine
              Library membership) could only be accessed through the unwieldy,
              visually overwhelming dropdown menu; there was no landing page for
              all of the digital resources. Prior to the redesign, each digital
              resource was only represented by its link name, lacking any
              description of what the resource offers. We added descriptions of
              the resources and also grouped them into categories, significantly
              enhancing the ease and efficacy of user search efforts.
            </p>
            <ImageOverlay
              src={cromaine_digital_resources}
              alt="Redesigned Digital Resources Page"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Calendar</h2>
            <p>
              For the calendar page, we added a quick access panel for featured
              events that are picked out by library staff, updated the calendar
              style so patrons could easily view upcoming events and we added a
              filter on the side of the page, to help users more easily access
              events of specific interest to them.
            </p>
            <ImageOverlay
              src={cromaine_calendar_redesign}
              alt="Calendar page before and after"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <h2>Outcome and Impact</h2>
            <p>
              The redesign was enthusiastically received by the library staff,
              who were humbly mindful of the challenges and frustrations the
              site presented to their patrons, and by the patrons themselves,
              who were eager to use their voices to improve this valued part of
              their community. Our redesign of the website's information
              architecture and most-used pages will be used by the library staff
              as inspiration and template for a large-scale redesign of their
              entire website.
            </p>
            <p>
              With our redesign as inspiration, the dedicated members of the
              Cromaine Library staff are excited to enhance their role in this
              rural community as a cultural and information hub, into one with
              increased accessibility, ease-of-use, and connectivity to the
              world outside of its physical walls.
            </p>
            <p>
              One staff member captured something in her statement of
              appreciation, that we hoped we had infused our redesign with:
              "Thank you ALL for your professionalism, talent, and joy. We're so
              grateful for absolutely everything!" It was, indeed, a UX
              designer's joy to design an interface that would help people of
              all ages better and more enjoyably access information and events
              from within the heart of their own community, their own public
              library.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cromaine;
