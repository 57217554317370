import React, { useState, useEffect } from 'react';

const AutoglyphsGenerator = () => {
  const [generatedString, setGeneratedString] = useState('');

  useEffect(() => {
    const ONE = 2 ** 32;
    const USIZE = 110;
    const SIZE = USIZE;
    const HALF_SIZE = SIZE / 2;
    // const SCALE = 1;
    // const SCALE = 234652748180987775515;
    // const HALF_SCALE = SCALE / 2;

    const symbols = ['.', 'X', '/', '\\', '+', '-', '|', '#', 'O'];

    const absValue = (n) => (n >= 0 ? n : -n);

    const drawUsingTime = () => {
      const a = Math.floor(new Date().getTime() / 3);
      let output = '';

      for (let i = 0; i < SIZE; i++) {
        let y = 2 * (i - HALF_SIZE) + 1;
        if (a % 3 === 1) y = -y;
        else if (a % 3 === 2) y = absValue(y);
        y = y * a;

        for (let j = 0; j < SIZE; j++) {
          let x = 2 * (j - HALF_SIZE) + 1;
          if (a % 2 === 1) x = absValue(x);
          x = x * a;

          const v = absValue(Math.floor((x * y) / ONE) % ((a % 11) + 5));

          if (v < symbols.length) {
            output += symbols[v];
          } else {
            output += '.';
          }
        }
        output += '\n';
      }

      return output;
    };

    const generatedString = drawUsingTime();
    setGeneratedString(generatedString);
  }, []);

  return (
    <div className='fixed left-0 right-0 w-screen h-screen flex items-center justify-center text-neutral-1 opacity-40 -z-10 text-[24px]' id='autoglyph'>
      {generatedString}
    </div>
  );
};

export default AutoglyphsGenerator;