const Footer = () => {

    return (
      <>
          <div className="w-full bg-white flex justify-center px-2 py-0 pt-28">
              {/* <div className="flex flex-col items-center gap-3">
                  <div className="text-4xl font-semibold text-neutral-9">Contact</div>
                  <div className="text-2xl font-medium text-neutral-8">bengrnb@umich.edu</div>
              </div> */}
          </div>
      </>
    );
    }
    export default Footer;