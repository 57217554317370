import { Helmet } from "react-helmet";
import ImageOverlay from '../base/ImageOverlay';

//Images
import harmony_logo from "../graphicsicons/harmony/harmony_logo.svg"
import harmony_tryone from "../graphicsicons/harmony/harmony_tryone.png"

const Harmony = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Harmony</title>\
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <div className="bg-[#fff] flex items-center justify-center text-neutral-9 px-4 md:px-8 pt-[92px]">
        <div className="flex flex-col md:px-4 w-full items-center pt-14 gap-y-[152px]">
            <div className="flex gap-4 w-full justify-center">
                <ImageOverlay  src={harmony_logo} alt="Harmony Banner" normalWidth="w-[64px]" normalHeight="h-auto" fullscreenWidth="w-full" fullscreenHeight="h-full" normalAdditionalClasses="" fullscreenAdditionalClasses="" showAlt={false} />
                <div className='text-neutral-9 text-5xl md:text-6xl font-medium text-center'>Harmony</div>
            </div>
            <div className="flex flex-col items-center max-w-4xl gap-6 font-normal text-xl text-neutral-8">
                {/* <div>Spotify, Apple Music, Tidal, Youtube Music, Pandora, Netflix, Peacock, Hulu, Amazon Prime, Disney+, Paramount Plus, Twitter, Instagram, Threads, Reddit, Facebook...</div>
                <div>Buying, renting, streaming, saving, downloading...</div>
                <div>Listening, watching, reading, posting...</div> */}
                <div>As the digital world grows, so does the number of digital entertainment options and their relentless competition for your attention. What if you could put all of your entertainment needs under a single umbrella, where they lived in perfect <b>Harmony</b>?</div>

            </div>
            <ImageOverlay  src={harmony_tryone} alt="Harmony Banner" normalWidth="w-full" normalHeight="h-auto" fullscreenWidth="w-full" fullscreenHeight="h-full" normalAdditionalClasses="" fullscreenAdditionalClasses="" showAlt={false} />
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 text-2xl mt-16 text-neutral-9">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#3A3A3C" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09l-.001.001Zm8.446-7.189L18 9.75l-.259-1.035a3.376 3.376 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456Zm-1.365 11.852L16.5 21.75l-.394-1.183a2.251 2.251 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.251 2.251 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.251 2.251 0 0 0 1.423 1.423l1.183.394-1.183.394a2.251 2.251 0 0 0-1.423 1.423Z"/></svg>
        <div>More designs, coming soon</div>
      </div>
    </>
  );
};

export default Harmony;
