import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ImageOverlay from "../base/ImageOverlay";
import tcd_homepage from "../graphicsicons/tcd/tcd_homepage.png";
import persona_employee from "../graphicsicons/tcd/persona_employee.png";
import persona_manager from "../graphicsicons/tcd/persona_manager.png";
import persona_customer from "../graphicsicons/tcd/persona_customer.png";
import tcd_resources from "../graphicsicons/tcd/tcd_resources.png";
import tcd_asset_management from "../graphicsicons/tcd/tcd_asset_management.png";
import tcd_warranty from "../graphicsicons/tcd/tcd_warranty.png";
import tcd_light_dark_mode from "../graphicsicons/tcd/tcd_light_dark_mode.png";
import tcd_styles from "../graphicsicons/tcd/tcd_styles.png";
import tcd_styles_two from "../graphicsicons/tcd/tcd_styles_two.png";
import tech_consulting_google_site from "../graphicsicons/tcd/tech_consulting_google_site.png";
import google_site_inventory from "../graphicsicons/tcd/google_site_inventory.png";
import figjam_link_groupings from "../graphicsicons/tcd/figjam_link_groupings.png";
import tcd_warranty_usertesting from "../graphicsicons/tcd/tcd_warranty_usertesting.png";
import resources_initial_test_interface from "../graphicsicons/tcd/resources_initial_test_interface.png";
import tcd_resources_lowfidelity from "../graphicsicons/tcd/tcd_resources_lowfidelity.png";
import tcd_resources_midfidelity from "../graphicsicons/tcd/tcd_resources_midfidelity.png";
import tcd_resources_highfidelity from "../graphicsicons/tcd/tcd_resources_highfidelity.png";
import tcd_resources_category_group_highfidelity from "../graphicsicons/tcd/tcd_resources_category_group_highfidelity.png";
import resources_implementation from "../graphicsicons/tcd/resources_implementation.gif";
import tdx_checkout_process from "../graphicsicons/tcd/tdx_checkout_process.png";
import asset_record_info_process from "../graphicsicons/tcd/asset_record_info_process.png";
import tcd_asset_management_formideation from "../graphicsicons/tcd/tcd_asset_management_formideation.png";
import tcd_assetmanagement_usertesting from "../graphicsicons/tcd/tcd_assetmanagement_usertesting.png";
import checkout_validation from "../graphicsicons/tcd/checkout_validation.gif";
import tcd_error_prevention from "../graphicsicons/tcd/tcd_error_prevention.png";
import tcd_checkout_success from "../graphicsicons/tcd/tcd_checkout_success.png";
import checkout_return_demo from "../graphicsicons/tcd/checkout_return_demo.gif";
import checkout_inbetween from "../graphicsicons/tcd/checkout_inbetween.png";
import laptop_checkout_precheck from "../graphicsicons/tcd/laptop_checkout_precheck.png";
import laptop_checkout_confirmation_postcheck from "../graphicsicons/tcd/laptop_checkout_confirmation_postcheck.png";
import dell_vs_lenovo_warranty_check from "../graphicsicons/tcd/dell_vs_lenovo_warranty_check.png";
import standardized_information from "../graphicsicons/tcd/standardized_information.png";
import detailed_warranty_information from "../graphicsicons/tcd/detailed_warranty_information.png";
import warranty_edge_cases from "../graphicsicons/tcd/warranty_edge_cases.png";
import warranty_landing from "../graphicsicons/tcd/warranty_landing.png";
import warranty_landing_no_results from "../graphicsicons/tcd/warranty_landing_no_results.png";
import warranty_not_serviceable from "../graphicsicons/tcd/warranty_not_serviceable.png";
import warranty_apple_partial_coverage from "../graphicsicons/tcd/warranty_apple_partial_coverage.png";
import warranty_serviceable_no_coverage from "../graphicsicons/tcd/warranty_serviceable_no_coverage.png";

const TCD = () => {
  const [resourcesPopupOpen, setResourcesPopupOpen] = useState(false);
  const [assetmanagementPopupOpen, setAssetmanagementPopupOpen] =
    useState(false);
  const [warrantyPopupOpen, setWarrantyPopupOpen] = useState(false);

  const toggleResourcesPopup = () => {
    setResourcesPopupOpen(!resourcesPopupOpen);
  };
  const toggleAssetmanagementPopup = () => {
    setAssetmanagementPopupOpen(!assetmanagementPopupOpen);
  };
  const toggleWarrantyPopup = () => {
    setWarrantyPopupOpen(!warrantyPopupOpen);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Tech Consulting Dashboard</title>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <div className="bg-[#fff] flex items-center justify-center text-neutral-9 px-6 md:px-8 pt-[92px]">
        <div className="flex flex-col max-w-3xl w-full pt-14 gap-y-24">
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-4">
              <div className="text-neutral-10 text-4xl md:text-5xl font-medium">
                Tech Consulting Dashboard
              </div>
              <div className="text-neutral-9">April 2023 - May 2024</div>
            </div>
            <div className="flex md:flex-row flex-col gap-12">
              <div className="flex w-full flex-col gap-3 pt-6 border-t border-neutral-3">
                <div className="font-medium text-2xl text-[#000]">Client</div>
                <div className="font-normal text-base text-neutral-8">
                  University of Michigan Information and Technology Services
                </div>
              </div>
              <div className="flex w-full flex-col gap-3 pt-6 border-t border-neutral-3">
                <div className="font-medium text-2xl text-[#000]">Role</div>
                <div className="font-normal text-base text-neutral-8">
                  UX Researcher, UX Designer, Front End Developer
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-20">
              <div className="flex flex-col gap-4">
                <div className="text-neutral-9 text-2xl font-semibold">
                  Overview
                </div>
                <div className="font-normal text-lg text-neutral-8">
                  Creating a Dashboard for the University of Michigan's
                  Information Technology Services Tech Consulting (ITS)
                  department to centralize and improve the tools Tech
                  Consultants use in their daily work.
                </div>
              </div>
              <ImageOverlay
                src={tcd_homepage}
                alt="Tech Consulting Dashboard Homepage"
                normalWidth="w-full"
                normalHeight="h-auto"
                fullscreenWidth="w-full"
                fullscreenHeight="h-full"
                normalAdditionalClasses=""
                fullscreenAdditionalClasses=""
                showAlt={true}
              />
            </div>
          </div>
          <div className="textblock">
            <h2>The Problem</h2>
            <p>
              The resources, information, processes and tools ITS Tech
              Consultants use in their daily work are difficult to locate, use
              and understand. As a result, employee work is more slow, confusing
              and error prone than it should be, which becomes especially
              problematic when Tech Consultants are assisting and interacting
              with University of Michigan Customers. Consultant confusion and
              mistakes often result in employee, manager and customer distress,
              and can be costly to resolve.
            </p>
          </div>
          <div className="textblock">
            <h2>The Goal</h2>
            <p>
              Create a "Tech Consulting Dashboard" to centralize the resources
              and information employees use to do their jobs, and put tools on
              the dashboard that are easier to use, enforce ITS trainings and
              policies and prevent consultant errors before they occur.
            </p>
          </div>
          <div className="textblock">
            <h2>Personas</h2>
            <h3>Tech Consulting Employee</h3>
            <ImageOverlay
              src={persona_employee}
              alt="Employee Persona"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
            <h3>Tech Consulting Manager</h3>
            <ImageOverlay
              src={persona_manager}
              alt="Manager Persona"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
            <h3>Tech Consulting Customer</h3>
            <ImageOverlay
              src={persona_customer}
              alt="Manager Customer"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Primary Deliverables</h2>
            <h3>Homepage</h3>
            <p>
              Employees can use the homepage to quickly access resources that
              they would typically need throughout the day, as well as view any
              pressing announcements and information leadership would like them
              to see. The responsive card components that make up the homepage
              allow for quick and easy maintenance.
            </p>
            <ImageOverlay
              src={tcd_homepage}
              alt="Tech Consulting Dashboard Homepage"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={true}
            />
            <div className="flex items-baseline gap-4 w-full justify-between">
              <h3>Resources</h3>
              <button
                onClick={toggleResourcesPopup}
                className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
              >
                <div>Details</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
              </button>
            </div>
            <p>
              The resources page acts as a central hub that contains all of the
              links (more than 60) to the resources and documentation
              consultants use. To make resources easy and quick to location,
              links are grouped into Categories and subcategories (Groups) and
              can also be located through a search feature. The resources page
              is typically updated weekly or biweekly.
            </p>
            <ImageOverlay
              src={tcd_resources}
              alt="Tech Consulting Dashboard Resources Page"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={true}
            />
            {resourcesPopupOpen && (
              <div className="backdrop-blur-3xl backdrop-saturate-200 w-full h-screen z-[1000] fixed top-0 left-0 flex justify-center">
                <div className="flex flex-col max-w-3xl w-full md:m-6 border rounded-md border-neutral-3 bg-[#FFFFFFCC] shadow-xl">
                  <div className="w-full max-w-3xl fixed text-lg flex justify-between items-center p-4 px-6 border-b border-neutral-3">
                    <p className="text-base font-semibold text-neutral-8">
                      Resources Details
                    </p>
                    <button
                      onClick={toggleResourcesPopup}
                      className="bg-white border border-neutral-3 hover:border-black rounded-full w-8 h-8 flex justify-center items-center hover:scale-125 transition ease-in-out delay-75"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col max-w-4xl w-full mt-[64px] pt-16 gap-y-24 px-4 md:px-6 pb-16 overflow-y-scroll">
                    <div className="textblock">
                      <h2>Resources: Pre Project State of Affairs</h2>
                      <p>
                        A separate Google Site, was being used to contain all
                        consultant resources. The site was around 20 years old
                        and contained 23 different nav elements. The resources
                        on the site took the form of embedded Google Docs, links
                        to external sites and articles written directly on the
                        site itself.
                      </p>
                      <p>
                        I was presented with the question: How might I integrate
                        the resources from the Google Site into the Tech
                        Consulting Dashboard?
                      </p>
                      <ImageOverlay
                        src={tech_consulting_google_site}
                        alt="Old Tech Consulting Google Site"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Old Site Inventory</h2>
                      <p>
                        There were over 100 unique links and resources on the
                        the old Tech Consulting website. The information was
                        documented and categorized, and then sent to the Tech
                        Consulting Department managers, where they marked out of
                        date resources. The irrelevant information was removed,
                        and ~50 links remained.
                      </p>
                      <ImageOverlay
                        src={google_site_inventory}
                        alt="Old Tech Consulting Google Site Inventory"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Grouping and Organizing Links</h2>
                      <p>
                        Once relevant links were identified, they were given
                        descriptions and grouped into distinct categories that
                        would make them easier to discover and understand.
                      </p>
                      <ImageOverlay
                        src={figjam_link_groupings}
                        alt="Grouped Links"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>User Testing</h2>
                      <p>
                        To test the initial work done to organize and group the
                        links, dropdown menus containing the link groups were
                        created and consultants were asked to locate specific
                        resources.
                      </p>
                      <p>
                        During the testing, it was discovered that while the
                        links groupings made sense to the Tech Consultants, it
                        was too overwhelming to see them all at once: the groups
                        needed to be organized further, so that employees were
                        presented with less information at a time.
                      </p>
                      <ImageOverlay
                        src={tcd_warranty_usertesting}
                        alt="User Testing Matrix"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                      <ImageOverlay
                        src={resources_initial_test_interface}
                        alt="Initial Resources Test Interface"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Low Fidelity Design</h2>
                      <p>
                        The resources interface separates links into two main
                        categories: "Customer Support" and "Employee Resources".
                        The customer support resources would be used to assist
                        employees with external requests such as printing a
                        poster, and the employee resources would help
                        consultants perform internal tasks such as reporting
                        time worked. In creating the two distinct categories,
                        the amount of resource information users see at a time
                        is effectively halved.
                      </p>
                      <ImageOverlay
                        src={tcd_resources_lowfidelity}
                        alt="Low Fidelity Resource Pages"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Mid Fidelity Design</h2>
                      <p>
                        At the bottom of the resources page, are the categories
                        that each of the individual groups belong to. While they
                        could be considered "Employee Resources," "Trainings"
                        have their own category (that does not show up in the
                        Link groups components above) because training resources
                        are one time use, and are never used by consultants
                        after their onboarding and training is complete.
                      </p>
                      <ImageOverlay
                        src={tcd_resources_midfidelity}
                        alt="Mid Fidelity Resource Pages"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>High Fidelity Design</h2>
                      <ImageOverlay
                        src={tcd_resources_highfidelity}
                        alt="High Fidelity Resources Landing Page"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                      <ImageOverlay
                        src={tcd_resources_category_group_highfidelity}
                        alt="High Fidelity Resources Group and Category Page View"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Implementation</h2>
                      <ImageOverlay
                        src={resources_implementation}
                        alt="Resources page in action"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <ImageOverlay
                        src={tcd_resources}
                        alt="Tech Consulting Dashboard Resources Page"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="w-full flex justify-center">
                      <button
                        onClick={toggleResourcesPopup}
                        className="text-neutral-9 font-normal text-xl md:text-2xl w-96 md:w-[512px] bg-neutral-1 p-4 md:p-6 text-center rounded-full border border-neutral-2 hover:bg-neutral-2 hover:scale-110 transition ease-in-out delay-75"
                      >
                        Close Resources Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-baseline gap-4 w-full justify-between">
              <h3>Asset Management</h3>
              <button
                onClick={toggleAssetmanagementPopup}
                className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
              >
                <div>Details</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
              </button>
            </div>
            <p>
              The asset management page contains software service tools that
              allow consultants to check out and return loaner laptops that are
              given to students attending the University of Michigan. The asset
              management tools improve and replace the slower, more manual and
              more error prone process that was previously being used to manage
              laptops.
            </p>
            <ImageOverlay
              src={tcd_asset_management}
              alt="Tech Consulting Dashboard Asset Management (Laptop Checkout)"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={true}
            />
            {assetmanagementPopupOpen && (
              <div className="backdrop-blur-3xl backdrop-saturate-200 w-full h-screen z-[1000] fixed top-0 left-0 flex justify-center">
                <div className="flex flex-col max-w-3xl w-full md:m-6 border rounded-md border-neutral-3 bg-[#FFFFFFCC] shadow-xl">
                  <div className="w-full max-w-3xl fixed text-lg flex justify-between items-center p-4 px-6 border-b border-neutral-3">
                    <p className="text-base font-semibold  text-neutral-8">
                      Asset Management Details
                    </p>
                    <button
                      onClick={toggleAssetmanagementPopup}
                      className="bg-white border border-neutral-3 hover:border-black rounded-full w-8 h-8 flex justify-center items-center hover:scale-125 transition ease-in-out delay-75"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col max-w-4xl w-full mt-[64px] pt-16 gap-y-24 px-4 md:px-6 pb-16 overflow-y-scroll">
                    <div className="textblock">
                      <h2>Loaner Laptop Program</h2>
                      <p>
                        The Information Technology Services (ITS) loaner laptop
                        program allows students to rent laptops if, for whatever
                        reason, they are not able to obtain one on their own.
                        There are thousands of laptops in the program and loan
                        durations range from a few weeks, to a few years.
                      </p>
                    </div>
                    <div className="textblock">
                      <h2>Asset Management: Pre-Project State of Affairs</h2>
                      <p>
                        The platform and processes Tech Consultants used to
                        manage the laptops in the loaner program contained a lot
                        of complexity which often caused mistakes to occur. Many
                        manual steps and checks were required to successfully
                        return or checkout a laptop for a student, and there was
                        a heavy reliance on training to correctly use the
                        complicated system, as the system itself lacked
                        technical safeguards to ensure a smooth experience.
                      </p>
                    </div>
                    <div className="textblock">
                      <h2>Pre-Project User Flow</h2>
                      <p>
                        Below is the user flow for the old process of checking
                        out a laptop for a customer. A lot of time is spent
                        determining if a customer is eligible for a loan and
                        setting up to acutally checkout out a laptop. When the
                        checkout is carried out, many manual steps are required
                        for successful completion.
                      </p>
                      <ImageOverlay
                        src={tdx_checkout_process}
                        alt="Pre-Project Laptop Checkout User Flow"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Eliminating Complexity</h2>
                      <p>
                        After gaining an understanding of how the current system
                        worked, and was expected to work, it was time to
                        determine how the system could be improved an automated.
                        An ideal system would be quick, straightforward and
                        wouldn't even give users the chance to make mistakes.
                      </p>
                      <p>
                        It was determined that the only information and
                        automated system would need to Return a laptop was the
                        laptop's asset number. The only information an automated
                        system would required to Check Out a laptop was the
                        laptop's asset number and the username for the customer
                        receiving it (referred to as a Uniqname). An optional
                        ability to leave comments about a request was also
                        helpful to have.
                      </p>
                      <p>
                        Below is a graphic breaking down how asset management
                        could be simplified through automation and hiding
                        nonessential information:
                      </p>
                      <ImageOverlay
                        src={asset_record_info_process}
                        alt="Reducing System Complexity by 80%"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Design Ideation</h2>
                      <p>
                        A form was created to send information to the dashboard
                        backend, that would automate the rest of the process.
                        The end goal was to make the process of checking out and
                        returning laptops as easy as checking out or returning a
                        book from the library. The form design and fidelity was
                        continuously improved and made to be more useable and
                        accessible.
                      </p>
                      <ImageOverlay
                        src={tcd_asset_management_formideation}
                        alt="Asset Management Form Ideation"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Testing</h2>
                      <p>
                        With each design iteration, testing was run to determine
                        how each design could be improved. Changes informed from
                        user testing included:
                      </p>
                      <p>&#x2022; Renaming “Check In” to “Return”</p>
                      <p>
                        &#x2022; Adding icons to the form to make it easier to
                        scan
                      </p>
                      <p>
                        &#x2022; Improving keyboard controls to allow users to
                        quickly complete the form without a mouse
                      </p>
                      <ImageOverlay
                        src={tcd_assetmanagement_usertesting}
                        alt="User Testing Matrix "
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Preventing, Handling and Fixing Errors</h2>
                      <h3>Data and Form Validation</h3>
                      <p>
                        The Checkout and Return form have data validation in
                        place to ensure that clean data is sent to the backend.
                        The grayed out "Submit" button at the bottom of the form
                        will not become active and let users submit the form
                        unless the data in all required fields is filled out
                        correctly.
                      </p>
                      <ImageOverlay
                        src={checkout_validation}
                        alt="Checkout Form Validation"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <h3>Error Detection and Recovery</h3>
                      <p>
                        Form validation ensures that “clean” data is sent to the
                        backend, however that does not ensure that “correct”
                        data is sent to the backend. For example, if an employee
                        is checking out a laptop for a customer and mistakenly
                        enters a Uniqname into “Unqiname” form field that exists
                        in the system, but is not the customer's Uniqname, there
                        needs to be a way to detect that a mistake has occurred
                        and help the user recover.
                      </p>
                      <p>
                        ~15 unique error triggers and messages were added to the
                        form design to help employees detect and correct any
                        mistakes they may have made.
                      </p>
                      <ImageOverlay
                        src={tcd_error_prevention}
                        alt="Error Alerts "
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Successful Form Completion: Initial Iteration</h2>
                      <p>
                        After consultants successfully completed an asset
                        management action, they were shown a confirmation with a
                        summary of the action they just completed.
                      </p>
                      <ImageOverlay
                        src={tcd_checkout_success}
                        alt="User Testing Matrix "
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Final Design Iteration</h2>
                      <p>
                        In the final design iteration, motion was added to
                        further communicate with users, and the form design was
                        expanded to cover and improve more aspects of the user
                        journey.
                      </p>
                      <h3>Extended User Journey and Errors</h3>
                      <p>
                        The asset management system proved to be very effective
                        at simplifying the process of checking out and returning
                        loaner laptops. However, issues arose that were caused
                        by a disconnect between the customer and employee, and
                        employees failing to remember specific trainging they
                        had received.
                      </p>
                      <p>
                        For example, an agent loaned a customer a Windows laptop
                        when they should have lent them a Mac laptop, because
                        the agent did not ask the customer what type of laptop
                        they were approved for and instead just assumed they
                        were approved for a Windows device, because Windows
                        loans were more common.
                      </p>
                      <p>
                        In another instance, a customer received a loaner
                        laptop, only to come back the next day and report that
                        it did not work. Consultants are supposed to make sure a
                        customer's loaner laptop works before the cusotmer
                        leaves.
                      </p>
                      <p>
                        Below is a diagram illustrating the steps that fall
                        before and after a consultant checks out a laptop.
                        In-between each step, important information needs to be
                        determined by the consultant and/or conveyed to the
                        customer:
                      </p>
                      <ImageOverlay
                        src={checkout_inbetween}
                        alt="The In-Between"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                      <h3>Pre Asset Management Checks</h3>
                      <p>
                        Precheck information was added to the side of the
                        checkout and return forms so that consultants could
                        glance at to familiarize themselves with the process,
                        and ensure that they don't forget to take any actions
                        the dashboard cannot control, such as checking if
                        loaner's hardware and software is intact.
                      </p>
                      <ImageOverlay
                        src={laptop_checkout_precheck}
                        alt="Checkout Precheck"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <h3>Post Asset Management Checks</h3>
                      <p>
                        In the post asset management pages, a summary of the
                        successful action is displayed on the right side of the
                        screen, with information such as the specific device
                        that was managed, the customer uniqname and any comments
                        left about the interaction. Information to ensure a
                        seamless transition to the next step of the asset
                        management user journey is displayed in the center of
                        the screen.
                      </p>
                      <p>
                        In addition to acting as a way to combat consultant
                        errors, the information in the center of the screen also
                        serves as a way to reinforce and catch any missed asset
                        management training. For that reason, information about
                        both Mac and Windows laptops is always show as opposed
                        to dynamically showing information relevant to the type
                        of device that was managed.
                      </p>
                      <ImageOverlay
                        src={laptop_checkout_confirmation_postcheck}
                        alt="Checkout Postcheck"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <h3>Demonstration</h3>
                      <ImageOverlay
                        src={checkout_return_demo}
                        alt="Checkout and Return Demo, Final Iteration "
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="w-full flex justify-center">
                      <button
                        onClick={toggleAssetmanagementPopup}
                        className="text-neutral-9 font-normal text-xl md:text-2xl w-96 md:w-[512px] bg-neutral-1 p-4 md:p-6 text-center rounded-full border border-neutral-2 hover:bg-neutral-2 hover:scale-110 transition ease-in-out delay-75"
                      >
                        Close Asset Management Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-baseline gap-4 w-full justify-between">
              <h3>Warranty</h3>
              <button
                onClick={toggleWarrantyPopup}
                className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
              >
                <div>Details</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
              </button>
            </div>
            <p>
              The Warranty page helps Tech Consultants determine if ITS is able
              to repair both University and non University laptops. Prior to the
              Warranty page, employees needed to consult multiple websites and
              trainings to determine a devices eligibility for service.
            </p>
            <ImageOverlay
              src={tcd_warranty}
              alt="Tech Consulting Dashboard Warranty"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={true}
            />
            {warrantyPopupOpen && (
              <div className="backdrop-blur-3xl backdrop-saturate-200 w-full h-screen z-[1000] fixed top-0 left-0 flex justify-center">
                <div className="flex flex-col max-w-3xl w-full md:m-6 border rounded-md border-neutral-3 bg-[#FFFFFFCC] shadow-xl">
                  <div className="w-full max-w-3xl fixed text-lg flex justify-between items-center p-4 px-6 border-b border-neutral-3">
                    <p className="text-base font-semibold text-neutral-8">
                      Warranty Details
                    </p>
                    <button
                      onClick={toggleWarrantyPopup}
                      className="bg-white border border-neutral-3 hover:border-black rounded-full w-8 h-8 flex justify-center items-center hover:scale-125 transition ease-in-out delay-75"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col max-w-4xl w-full mt-[64px] pt-16 gap-y-24 px-4 md:px-6 pb-16 overflow-y-scroll">
                    <div className="textblock">
                      <h2>Laptop Warranty Checking: Context</h2>
                      <p>
                        One of the in-person technical services the Tech
                        Consulting department offers is troubleshooting and
                        assisting University affiliates with hardware issues.
                      </p>
                      <p>
                        When a customer approaches a consultant with a laptop
                        hardware issue, it is up to the consultant to first
                        diagnose what the root cause of the problem is, and then
                        determine if and how they can assist a customer.
                      </p>
                      <p>
                        If a hardware issue is small, such as needing to
                        reattach a spacebar to a keyboard, consultants may be
                        able to assist customers on the spot. However, if the
                        issue is larger, such as a broken laptop screen,
                        consultants will need to determine if the more
                        specialized ITS Tech Repair department is able to
                        service the broken device, and if the device has a
                        warranty that would cover repair costs.
                      </p>
                    </div>
                    <div className="textblock">
                      <h2>Challenges</h2>
                      <p>
                        Many different types and brands of laptop make their way
                        to Tech Consultants. It's possible for laptops to have
                        different types of warranties and warranty providers
                        (e.g manufacture vs. 3rd party). Even if a device does
                        have a warranty, the University is only able to service
                        certain types of devices that have met specific
                        criteria. For example, the University is able to service
                        Apple devices that are bought internationally but can
                        only service HP devices that are bought in the US.
                      </p>
                      <p>
                        The already difficult task of diagnosing laptop hardware
                        issues can be made much more difficult by the complexity
                        and variability involved with determining device
                        warranty and service eligibility.
                      </p>
                      <p>
                        Without a dedicated tool to help Tech Consultants with
                        determining warranty and service eligibility, they must
                        consult multiple information sources to make accurate
                        decisions, including training materials, Tech Repair
                        employees and warranty websites (a different site for
                        each different laptop brand). The lack of centralization
                        of information can increase consultant error rate and
                        resolution time.
                      </p>
                      <ImageOverlay
                        src={dell_vs_lenovo_warranty_check}
                        alt="Dell vs Lenovo Warranty Check Pages"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Considerations</h2>
                      <h3>
                        Laptop Device and Warranty Information Standardization
                      </h3>
                      <p>
                        Getting information about each different brand of laptop
                        and the warranty(s) for those laptops entails getting
                        information from many different APIs. Ideally, users
                        would see the same information, regardless of the type
                        of device they are looking up. However, different APIs
                        will not all provide the same information.
                      </p>
                      <p>
                        The information shown when running a warranty check
                        needed to be information that could easily be found on
                        all or most APIs.
                      </p>
                      <ImageOverlay
                        src={standardized_information}
                        alt="Standardized Information, Regardless of Device Type"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                      <h3>Information Discoverability</h3>
                      <p>
                        There is a wide range of serviceability requirements for
                        different device types. Additionally, there can be both
                        manufacturer and 3rd party warranties applied to a
                        laptop. A manufacturer warranty usually covers hardware
                        defects, but not accidental damage, whereas 3rd party
                        warranties will cover accidental damage.
                      </p>
                      <p>
                        When checking a laptop warranty, users need to be
                        presented with an informative and simple view, but
                        should also have the opportunity to get more detailed
                        information than was initially presented if necessary.
                      </p>
                      <ImageOverlay
                        src={detailed_warranty_information}
                        alt="Detailed Warranty Information"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                      <h3>Edge Cases, Error Prone Situations</h3>
                      <p>
                        The interface also needs to prevent error when users are
                        presented with niche situations or with tasks that
                        require additional context and/or actions for success.
                      </p>
                      <p>
                        For example, it is possible for a device to be under
                        warranty but not serviceable by the University. In
                        situations like this, it is helpful to let a customer
                        know that their device may be able to be serviced
                        elsewhere, but it also needs to be clear that the
                        University cannot service a device in such a situation.
                      </p>
                      <p>
                        Another example is with Apple devices. An Apple device
                        may be under warranty and serviceable by the University,
                        but if the laptop “Find My” feature is not turned off
                        prior to sending the device to the Tech Repair
                        department, they will be unable to repair it.
                      </p>
                      <ImageOverlay
                        src={warranty_edge_cases}
                        alt="Warranty Edge Cases"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={true}
                      />
                    </div>
                    <div className="textblock">
                      <h2>Final Designs</h2>
                      <ImageOverlay
                        src={warranty_landing}
                        alt="Warranty Landing"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <ImageOverlay
                        src={warranty_landing_no_results}
                        alt="Warranty Landing No Results"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <ImageOverlay
                        src={tcd_warranty}
                        alt="Tech Consulting Dashboard Landing"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <ImageOverlay
                        src={warranty_not_serviceable}
                        alt="Warranty Not Serviceable"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <ImageOverlay
                        src={warranty_apple_partial_coverage}
                        alt="Warranty Apple Partial Coverage"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                      <ImageOverlay
                        src={warranty_serviceable_no_coverage}
                        alt="Warranty Serviceable No Coverage"
                        normalWidth="w-full"
                        normalHeight="h-auto"
                        fullscreenWidth="w-full"
                        fullscreenHeight="h-full"
                        normalAdditionalClasses=""
                        fullscreenAdditionalClasses=""
                        showAlt={false}
                      />
                    </div>
                    <div className="w-full flex justify-center">
                      <button
                        onClick={toggleWarrantyPopup}
                        className="text-neutral-9 font-normal text-xl md:text-2xl w-96 md:w-[512px] bg-neutral-1 p-4 md:p-6 text-center rounded-full border border-neutral-2 hover:bg-neutral-2 hover:scale-110 transition ease-in-out delay-75"
                      >
                        Close Warranty Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="textblock">
            <h2>Light Mode / Dark Mode</h2>
            <ImageOverlay
              src={tcd_light_dark_mode}
              alt="Tech Consulting Dashboard Lightmode and Darkmode"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Branding</h2>
            <ImageOverlay
              src={tcd_styles}
              alt="Tech Consulting Dashboard Styles"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
            <ImageOverlay
              src={tcd_styles_two}
              alt="Tech Consulting Dashboard Styles Two"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Outcome and Impact</h2>
            <p>
              The Tech Consulting Dashboard (TCD) is currently being used by the
              ~50 person Tech Consulting Department and has completely replaced
              the previous site the department had been using. More and more
              tools and resources continue to be added to the dashboard, as the
              department's processes, tools and resources continue to be
              optimized. The designs created for the dashboard range from being
              fully implemented into the site, to informing upcoming development
              for the TCD.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TCD;
