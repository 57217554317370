const EducationWork = () => {
  return (
    <>
      <div className="bg-[#fff] flex flex-col items-center justify-center text-neutral-9 px-8">
        <div className="max-w-5xl w-full flex gap-x-20 mt-9 md:flex-row flex-col gap-y-28">
          <div className="w-full border-t border-neutral-2 pt-9 flex gap-14 flex-col">
            <div className="text-4xl text-neutral-9 font-semibold">
              Work History
            </div>
            <div className="flex flex-col gap-9">
              <div className="flex flex-col gap-3">
                <div className="text-2xl text-neutral-8 font-medium">
                  Product Designer
                </div>
                <div className="flex-flex-col gap-2 text-base">
                  <div>Ford</div>
                  <div>May 2024 - Present</div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-2xl text-neutral-8 font-medium">
                  UX Consultant
                </div>
                <div className="flex-flex-col gap-2 text-base">
                  <div>PIXO VR</div>
                  <div>February 2024 - Present</div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-2xl text-neutral-8 font-medium">
                  UX Designer
                </div>
                <div className="flex-flex-col gap-2 text-base">
                  <div>
                    University of Michigan Information and Technology Services
                  </div>
                  <div>April 2023 - May 2024</div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-2xl text-neutral-8 font-medium">
                  IT Consultant and Student Coordinator
                </div>
                <div className="flex-flex-col gap-2 text-base">
                  <div>
                    University of Michigan Information and Technology Services
                  </div>
                  <div>February 2021 - May 2024</div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-2xl text-neutral-8 font-medium">
                  UX Research and Design Intern
                </div>
                <div className="flex-flex-col gap-2 text-base">
                  <div>City of Ann Arbor</div>
                  <div>May 2022 - July 2022</div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-2xl text-neutral-8 font-medium">
                  UX Designer and Researcher
                </div>
                <div className="flex-flex-col gap-2 text-base">
                  <div>Cromaine District Library</div>
                  <div>January 2022 - April 2022</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex gap-14 flex-col">
            <div className="flex border-t border-neutral-2 gap-14 flex-col pt-9">
              <div className="text-4xl text-neutral-9 font-semibold">
                Education
              </div>
              <div className="flex flex-col gap-9">
                <div className="flex flex-col gap-3">
                  <div className="text-2xl text-neutral-8 font-medium">
                    MSI - UX Research and Design
                  </div>
                  <div className="flex-flex-col gap-2 text-base">
                    <div>University of Michigan</div>
                    <div>August 2021 - May 2023</div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="text-2xl text-neutral-8 font-medium">
                    BA - Psychology
                  </div>
                  <div className="flex-flex-col gap-2 text-base">
                    <div>University of Michigan</div>
                    <div>Minor - Entrepreneurship </div>
                    <div>August 2016 - May 2020</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex border-t border-neutral-2 gap-14 flex-col pt-9 mt-9">
              <div className="text-4xl text-neutral-9 font-semibold">
                Certifications
              </div>
              <div className="flex flex-col gap-9">
                <div className="flex flex-col gap-3">
                  <div className="text-2xl text-neutral-8 font-medium">
                    Google UX Design
                  </div>
                  <div className="flex-flex-col gap-2 text-base">
                    <div>Google/Coursera</div>
                    <div>February 2024</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationWork;
