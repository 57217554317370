import Landing from "../components/home/Landing";
import SelectedWork from "../components/home/SelectedWork";
import { Helmet } from 'react-helmet';
const Home = () => {


  return (
    <>
     <div>
        <Helmet>
          <title>Home</title>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <Landing />
      <SelectedWork />
    </>
  );
};

export default Home;