import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageOverlay = ({ src, alt, normalWidth, normalHeight, fullscreenWidth, fullscreenHeight, fullscreenAdditionalClasses, normalAdditionalClasses, showAlt }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpen(true);
    document.body.classList.add('overlay-open'); // Add class to body
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    document.body.classList.remove('overlay-open'); // Remove class from body
  };

  return (
    <>
      {isOverlayOpen && (
        <div className={`image-overlay overlay cursor-zoom-out ${fullscreenAdditionalClasses}`} onClick={closeOverlay}>
          {/* Full-screen overlay content */}
          <img src={src} alt={alt} className={`${fullscreenWidth} ${fullscreenHeight}`} />
        </div>
      )}

      <button onClick={openOverlay}>
        {/* Thumbnail or regular image */}
        <div className={`cursor-zoom-in ${normalWidth} ${normalHeight} ${normalAdditionalClasses}`}>
          <img src={src} alt={alt} />
          {showAlt && <p className="image-alt">{alt}</p>}
        </div>
      </button>
    </>
  );
};

ImageOverlay.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  normalWidth: PropTypes.string.isRequired,
  normalHeight: PropTypes.string.isRequired,
  fullscreenWidth: PropTypes.string.isRequired,
  fullscreenHeight: PropTypes.string.isRequired,
  fullscreenAdditionalClasses: PropTypes.string, // Additional classes for fullscreen mode
  normalAdditionalClasses: PropTypes.string, // Additional classes for normal mode
  showAlt: PropTypes.bool, // Whether to show alt text in normal view
};

ImageOverlay.defaultProps = {
  showAlt: true, // Default to true if not provided
};

export default ImageOverlay;
