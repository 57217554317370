import React from "react";
import { Helmet } from "react-helmet";
import ImageOverlay from "../base/ImageOverlay";
import parkit_card_mockup from "../graphicsicons/parkit/parkit-card-mockup.png";
import parkit_homescreen_available_spaces from "../graphicsicons/parkit/parkit_homescreen_available_spaces.png";
import parkit_competitive_analysis from "../graphicsicons/parkit/parkit_competitive_analysis.png";
import parkit_stakeholder_analysis from "../graphicsicons/parkit/parkit_stakeholder_analysis.png";
import parkit_logos from "../graphicsicons/parkit/parkit_logos.png";
import parkit_login_low from "../graphicsicons/parkit/parkit_login_low.png";
import parkit_login_mid from "../graphicsicons/parkit/parkit_login_mid.png";
import parkit_login_high from "../graphicsicons/parkit/parkit_login_high.png";
import parkit_login_home_search from "../graphicsicons/parkit/parkit_login_home_search.png";
import parkit_results_details from "../graphicsicons/parkit/parkit_results_details.png";

const Parkit = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Parkit</title>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <div className="bg-[#fff] flex items-center justify-center text-neutral-9 px-6 md:px-8 pt-[92px]">
        <div className="flex flex-col max-w-3xl w-full pt-14 gap-y-24">
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-4">
              <div className="text-neutral-10 text-4xl md:text-5xl font-medium">
                Parkit
              </div>
              <div className="text-neutral-9">September - December 2022</div>
            </div>
            <div className="flex md:flex-row flex-col gap-12">
              <div className="flex w-full flex-col gap-3 pt-6 border-t border-neutral-3">
                <div className="font-medium text-2xl text-[#000]">Client</div>
                <div className="font-normal text-base text-neutral-8">
                  Unsolicited tool for Ann Arbor residents
                </div>
              </div>
              <div className="flex w-full flex-col gap-3 pt-6 border-t border-neutral-3">
                <div className="font-medium text-2xl text-[#000]">Role</div>
                <div className="font-normal text-base text-neutral-8">
                  UX Researcher, UX Designer
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-20">
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap items-baseline gap-4 w-full justify-between">
                  <h3 className="text-neutral-9 text-2xl font-semibold">
                    Overview
                  </h3>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.figma.com/proto/VV8hgl7ksZmoBxMdb94du3/Main?page-id=672%3A2494&node-id=672%3A2494&viewport=601%2C4%2C0.08&scaling=scale-down&starting-point-node-id=672%3A5166"
                    className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
                  >
                    <div>View Figma Mockup</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="font-normal text-lg text-neutral-8">
                  Myself and two other team members proposed and created a
                  project that aimed to be realistically implementable and have
                  a profitable and realistic business model. Our primary goal
                  was to improve the parking situation of Ann Arbor by
                  addressing its problems. We were interested in creating a
                  mutually beneficial exchange between residential parking space
                  owners and potential renters to alleviate some of the
                  challenges with parking in Ann Arbor.
                </div>
              </div>
              <ImageOverlay
                src={parkit_card_mockup}
                alt="Parkit banner"
                normalWidth="w-full"
                normalHeight="h-auto"
                fullscreenWidth="w-full"
                fullscreenHeight="h-full"
                normalAdditionalClasses=""
                fullscreenAdditionalClasses=""
                showAlt={false}
              />
            </div>
          </div>
          <div className="textblock">
            <h2>The Problem</h2>
            <p>
              Ann Arbor parking is much more expensive and difficult to find
              than it should be. Especially in crowded areas and during popular
              events such as football games, residents can spend a lot of time
              driving around looking for parking spaces, which wastes time and
              causes distress and confusion.
            </p>
            <ImageOverlay
              src={parkit_homescreen_available_spaces}
              alt="Home screen and available spaces screen"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Research</h2>
            <p>
              Throughout our project, our team utilized numerous UX research
              methods to investigate how to design and create a fair platform
              that addresses user needs properly. We chose to incorporate
              methodologies that would allow us to hear and understand what our
              target user group felt about various topics surrounding Ann Arbor,
              parking preferences, concerns, and other feelings. Given our
              resource constraints and COVID-19, we chose to address the
              following questions throughout our research.
            </p>
            <p>We identified several questions we wanted to solve:</p>
            <p>&#x2022; What factors influence parking behavior the most?</p>
            <p>
              &#x2022; What are the most common situations that require
              locating/using paid parking?
            </p>
            <p>
              &#x2022; Are there any parking frustrations present in Ann Arbor?
            </p>
            <p>
              &#x2022; If parking frustrations exist, what are the most
              important contributing factors to parking frustrations?
            </p>
            <p>
              &#x2022; What attitudes are associated with the idea of renting
              out a parking space to someone else in exchange for money?
            </p>
            <p>
              &#x2022; What is people's willingness to use a service to find
              parking spaces?
            </p>
          </div>
          <div className="textblock">
            <h2>Primary User Groups</h2>
            <p>
              We identified two main user groups for Parkit: Drivers within Ann
              Arbor and Ann Arbor Residents & Businesses.
            </p>
            <p>
              Ann Arbor drivers are users that can be identified as 'Renters' or
              'Guests', with the ability to browse parking spaces, locate
              parking amenities, and reserve parking spaces Ann Arbor Residents
              and Businesses, also known as 'Sellers' and 'Hosts,' can use
              Parkit as a platform for Ann Arbor residents to list, rent, and
              purchase residential spaces.
            </p>
          </div>
          <div className="textblock">
            <h2>Competitive Analysis</h2>
            <p>
              All the nine competitors for Parkit we evaluated using a
              competitive analysis met a specific and sometimes niche need for
              their customers. They had some of the features we were looking to
              include in Parkit, but none of them entirely address the parking
              problem we are aiming to solve in the way Parkit could, indicating
              that the project we have proposed is unique. All the competitors
              we evaluated but Uber and Spin focused on selling users parking
              spaces. Apart from the Neighbor platform, these competitors (Gas
              Buddy, Plug Share, Spot Hero, Parkstash, Spot Angels, Air Garage)
              all seemed to struggle with user interface problems and a lack of
              users and spaces listed on the platforms.
            </p>
            <p>
              There be a single platform that acts as a central informational
              hub for all matters related to locating and reserving study
              spaces: a platform that meets general and niche needs for a wide
              range of users will increase the supply of both buyers and sellers
              on the platform, resulting in an increase in the platforms value
              for both buyers and sellers.
            </p>
            <ImageOverlay
              src={parkit_competitive_analysis}
              alt="Parkit competitive analysis"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Stakeholder Analysis</h2>
            <p>
              We identified three key groups that would need to be managed
              closely for our project:
            </p>
            <p>&#x2022; Business staff</p>
            <p>&#x2022; Commercial, residential and public space renters</p>
            <p>&#x2022; Residential space sellers</p>
            <p>
              Our project focused on the space buyers and sellers. Business
              staff were out of scope because there were no employees or
              additional team members to manage outside of our class project
              group.
            </p>
            <ImageOverlay
              src={parkit_stakeholder_analysis}
              alt="Parkit stakeholder analysis"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Surveys</h2>
            <p>
              Through our survey, we were interested to learn general prctices
              and beliefs of potential users, specifically users with users who
              had access to a vehicle.
            </p>
            <p>
              &#x2022; Most people park in Ann Arbor weekly (besides their
              residence)
            </p>
            <p>
              &#x2022; Most parked in a parking lot, followed by structures and
              meters
            </p>
            <p>
              &#x2022; People did not always have a choice of where they parked
            </p>
            <p>
              &#x2022; Price, convenience, location, and availability were top
              factors of choosing parking
            </p>
            <p>
              &#x2022; Everyone had to work or go to class in person at least
              some of the time
            </p>
            <p>
              &#x2022; Most difficult part: finding a spot, affording a spot
            </p>
            <p>&#x2022; Easiest part: fitting vehicle in spot</p>
            <p>&#x2022; Overwhelming use of navigation tools</p>
            <p>
              &#x2022; Majority of people do not use any parking tools, but
              would be interested
            </p>
            <p>&#x2022; Majority felt cost “could be better”</p>
            <p>
              &#x2022; Majority of people limit instances of paying for parking,
              and park at less convenient spots to save money
            </p>
            <p>
              &#x2022; Top things to improve: availability, followed by price
            </p>
          </div>
          <div className="textblock">
            <h2>Design - Color</h2>
            <p>
              The primary brand color used was a yellow hue with hex code{" "}
              <span className="bg-[#FFDC13] text-[#000]">#FFDC13</span>, that we
              named “Sulfur.” Secondary colors include gray, red and green
              colors, named carbon, ruby and emerald. The use of the gray and
              yellow colors were to give the system a “dark mode” appearance as
              well as look like a parking space with the black asphalt and
              yellow parking lines.
            </p>
          </div>
          <div className="textblock">
            <h2>Design - Typeface</h2>
            <p>
              The typeface used was Montserrat Alternates, a modification of the
              clean and modern sans-serif Montserrat typeface chosen for its
              unique attributes suggesting momentum.
            </p>
          </div>
          <div className="textblock">
            <h2>Design - Logo</h2>
            <p>
              The logo consists of two arrows (chevrons) in a box, to look like
              a parking sign. This logomark was inspired by creating the “k” in
              the logotype with the chevron glyph. The typeface used in “Parkit”
              text is Montserrat Alternates. We selected this typeface for the
              logo because of the geometric counters and the forward
              directionality of the “t” character.
            </p>
            <ImageOverlay
              src={parkit_logos}
              alt="Parkit logos"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
            />
          </div>
          <div className="textblock">
            <h2>Prototying</h2>
            <p>
              A “main flow” was created to outline the overall flow of logging
              into the Parkit app, searching for a parking space, reserving a
              space and then navigating to a space. Once the master flow was
              created, detailed flows from each of the steps in the master flow
              were created to prepare for the design process. Wireframes were
              created from each of the detailed individual user flows, with were
              then turned into medium and then high fidelity prototypes.
            </p>
            <ImageOverlay
              src={parkit_login_low}
              alt="Low Fidelity Login User Flow"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
            <ImageOverlay
              src={parkit_login_mid}
              alt="Mid Fidelity Login User Flow"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
            <ImageOverlay
              src={parkit_login_high}
              alt="High Fidelity Login User Flow"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
            />
          </div>
          <div className="textblock">
            <div className="flex flex-wrap items-center gap-4 w-full justify-between">
              <h2>Final Design</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.figma.com/proto/VV8hgl7ksZmoBxMdb94du3/Main?page-id=672%3A2494&node-id=672%3A2494&viewport=601%2C4%2C0.08&scaling=scale-down&starting-point-node-id=672%3A5166"
                className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
              >
                <div>View Figma Mockup</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                  />
                </svg>
              </a>
            </div>
            <p>
              Our final design is composed of a high-fidelity interactive
              prototype built in Figma. Designed with the intention of
              simulating the ideal path a user would take to find and reserve a
              parking space:
            </p>
            <ImageOverlay
              src={parkit_login_home_search}
              alt="Parkit Login, Home and Search"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              showAlt={false}
            />
            <ImageOverlay
              src={parkit_results_details}
              alt="Parkit Search Results and Details"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              showAlt={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Parkit;
