import { Helmet } from "react-helmet";
import internship_infographic from "../assets/internship-infographic.pdf";
import ImageOverlay from "../base/ImageOverlay";
import infographic_moodboard from "../graphicsicons/internshipinfographic/infographic_moodboard.png";
import infographic_color_typography from "../graphicsicons/internshipinfographic/infographic_color_typography.png";
import infographic_initial_design from "../graphicsicons/internshipinfographic/infographic_initial_design.png";
import infographic_final_styles from "../graphicsicons/internshipinfographic/infographic_final_styles.png";
import internship_final_infographic from "../graphicsicons/internshipinfographic/internship_final_infographic.png";

const InternshipInfographic = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Internship Infographic</title>\
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-Q6F2WV9G29`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Q6F2WV9G29');
            `}
          </script>
        </Helmet>
      </div>
      <div className="bg-[#fff] flex items-center justify-center text-neutral-9 px-6 md:px-8 pt-[92px]">
        <div className="flex flex-col max-w-3xl w-full pt-14 gap-y-24">
          <div className="flex flex-col gap-20">
            <div className="text-neutral-10 text-4xl md:text-5xl font-medium">
              City of Ann Arbor Internship Infographic
            </div>
            <div className="flex flex-col gap-20">
              <div className="flex flex-col gap-4">
                <div className="flex flex-wrap items-baseline gap-4 w-full justify-between">
                  <h3 className="text-neutral-9 text-2xl font-semibold">
                    Overview
                  </h3>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={internship_infographic}
                    className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
                  >
                    <div>View Full Size Infographic</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="font-normal text-lg text-neutral-8">
                  I created an infographic of the different locations I visited
                  in Ann Arbor Michigan during my summer internship with the
                  City of Ann Arbor. For the duration of the internship, I
                  worked to create an online engagement hub to allow Ann Arbor
                  residents to participate in City governance decisions.
                  Engagement opportunities include opportunities to participate
                  in decisions-making and other city programs, including
                  volunteering opportunities.
                </div>
              </div>
            </div>
          </div>
          <div className="textblock">
            <h2>Making the infographic</h2>
            <p>
              To create the infographic, I first wrote down a narrative about my
              internship, documenting the general and unique parts of the job
              and different keywords to describe the experience. After creating
              the text summary, I created initial sketches for my infographic
              design. I then created a moodboard of images to inspire the visual
              identity for the infographic and identified potential typefaces
              and colors. Finally, I created more designs and continued to
              refine my work until a final internship infographic had been
              created.
            </p>
          </div>
          <div className="textblock">
            <h2>Written narrative and keywords</h2>
            <p>
              Below is some of the narrative and keywords chosen to describe the
              internship
            </p>
            <h3>Written narrative and keywords</h3>
            <p>
              Class, City of Ann Arbor, City Hall, UMSI, Kerrytown, Work from
              home, Virtual meetings, Ann Arbor summer
            </p>
            <h3>Narrative</h3>
            <p>
              &#x2022; Worked as a UX researcher and designer to build an online
              engagement hub to help Ann Arbor residents engage with the City
            </p>
            <p>
              &#x2022; Worked on a four person student team (including myself)
              with two UX and two urban planning students
            </p>
            <p>
              &#x2022; At the start of the project (for the first week) reviewed
              UX research that a previous student team had conducted to start
              the project
            </p>
            <p>
              &#x2022; In addition to conducting UX research and design work
              throughout the internship, acted as the project manager for the
              duration of the project
            </p>
            <p>
              &#x2022; Conducted interviews with business owners and Ann Arbor
              residents in the area to get a better understanding of residents
              are looking for
            </p>
          </div>
          <div className="textblock">
            <h2>Moodboard</h2>
            <p>
              Below is a moodboard I created that was mainly inspired by summers
              in Ann Arbor:
            </p>
            <ImageOverlay
              src={infographic_moodboard}
              alt="Infographic Moodboard"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Potential Colors and Typography</h2>
            <p>
              Inspired from the moodboard, narrative and keywords, I identified
              potential colors and typefaces to use for the infographic.
            </p>
            <ImageOverlay
              src={infographic_color_typography}
              alt="Initial Styles"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Initial Design</h2>
            <p>
              I created an initial rough design using the inspiration and
              materials I had created earlier.
            </p>
            <ImageOverlay
              src={infographic_initial_design}
              alt="Initial Design"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <h2>Final Styles</h2>
            <p>
              Below are the styles I decided to use for the final infographic
              design:
            </p>
            <ImageOverlay
              src={infographic_final_styles}
              alt="Final Styles"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
          <div className="textblock">
            <div className="flex flex-wrap items-center gap-4 w-full justify-between">
              <h2>Final Design</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href={internship_infographic}
                className="text-neutral-1 font-medium text-sm bg-neutral-9 hover:scale-105 active:scale-100 px-3 py-1 w-fit h-fit text-center rounded-full transition ease-in-out delay-75 flex gap-1 items-center"
              >
                <div>View Full Size Infographic</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                  />
                </svg>
              </a>
            </div>
            <p>
              After multiple ideas and iterations, I landed on making a map of
              the City of Ann Arbor that highlighted the different places I
              visited throughout the City to do my internship work.
            </p>
            <ImageOverlay
              src={internship_final_infographic}
              alt="Final Infographic Design"
              normalWidth="w-full"
              normalHeight="h-auto"
              fullscreenWidth="w-full"
              fullscreenHeight="h-full"
              normalAdditionalClasses=""
              fullscreenAdditionalClasses=""
              showAlt={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InternshipInfographic;
